import AES from 'crypto-js/aes'
import * as CryptoJS from 'crypto-js'

const secret = '!!@@121'

const encryptData = (originalText) => {
    if (!originalText) return
    return AES.encrypt(originalText, secret).toString();
}

const decryptData = (encryptText) => {
    if (!encryptText) return
    let bytes = AES.decrypt(encryptText, secret);
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText
}

export {
    encryptData,
    decryptData
}