import { Util } from './util'
import { getCookie, setCookie, deleteCookie } from './cookies'
const USER = 'USER'

const isLogin = () => {
    return Util.isNotNull(getCookie(USER))
}

const setToken = (token) => {
    return setCookie(USER, token)
}

const deleteToken = () => {
    return deleteCookie(USER)
}

const getToken = () => {
    return getCookie(USER)
}

export {
    isLogin,
    setToken,
    deleteToken,
    getToken
}