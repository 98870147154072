
import { useLocation } from "react-router";

const useRedirectQuery = () => {
    let query = new URLSearchParams(useLocation().search)
    const redirectUrl = query.get('redirectUrl')
    return redirectUrl
}

const useRedirectEmail = () => {
    let query = new URLSearchParams(useLocation().search)
    const redirectUrl = query.get('email')
    return redirectUrl
}

export {
    useRedirectQuery,
    useRedirectEmail
}