export default class Api {
  getOptions(url, body, qs, header = {}) {
    let queryParam
    if (qs) {
      queryParam = new URLSearchParams(qs).toString()
    }

    return { url, qs: queryParam, header, body }
  }

  get(options) {
    let url = options.url
    if (options.queryParam) {
      url = url + '?' + options.queryParam
    }
    return fetch(url, {
      method: 'GET',
      headers: {
        ...options.header
      }

    })
  }

  getJSON(options) {
    return this.get(options).then(res => res.json())
  }

  postJSON(options) {
    let url = options.url
    if (options.queryParam) {
      url = url + '?' + options.queryParam
    }

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(options.body),
      headers: {
        ...options.header,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(response => {
        if (!response.error) {
          return response
        } else {
          return Promise.reject(response)
        }
      })
  }
}
