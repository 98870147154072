import React, { useState } from 'react'
import { Card, CardTitle, Textfield, Button } from 'react-mdl'
import { Link } from 'react-router-dom'
import UserApi from '../../../services/api/user'
import { useToasts } from 'react-toast-notifications'
import { AppLayout } from '../../shared/compoent/app-layout'
import { isLogin, setToken } from '../../shared/service/auth'
import { useRedirectEmail, useRedirectQuery } from '../../shared/hooks/query-param'
import Util from '../../shared/service/util'
import { ShowLoader } from '../../shared/compoent/loader'
import { useHistory } from "react-router-dom";

/**
 * Login user function
 * @param {*} param0 
 */
const loginUser = async ({
  email, password, userApi, setLoader, redirectUrl, history, addToast
}) => {
  try {

    if (email && password) {
      setLoader(true)
      let response = await userApi.login(
        email,
        password
      )

      setToken(response.data.authToken)
      setLoader(false)
      if (redirectUrl) {
        Util.redirectUrl(redirectUrl)
      } else {
        Util.redirectToApp()
      }

    } else {
      addToast('Email and Password Required', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  } catch (error) {
    setLoader(false)
    console.log('error', error)
    addToast(error.message, {
      appearance: 'error',
      autoDismiss: true
    })
  }
}

const LoginFrom = () => {
  const redirectEmail = useRedirectEmail()
  let [email, setEmail] = useState(redirectEmail || '')
  let [password, setPassword] = useState('')
  let [isLoderVisble, setLoader] = useState(false)
  const userApi = new UserApi()
  const { addToast } = useToasts()
  const redirectUrl = useRedirectQuery()
  const history = useHistory();
  let _isLogin = isLogin()
  if (_isLogin) {
    Util.redirectToApp()
  }


  return (
    <AppLayout>
      <Card shadow={5}>
        <CardTitle className='header-title'>
          Sing in to your account
                  </CardTitle>
        <div style={{ margin: '0 5%' }}>
          <Textfield
            onChange={e => {
              e.preventDefault()
              setEmail(e.target.value)
            }}
            label='Email*'
            floatingLabel
            style={{ width: '100%' }}
            type='email'
            value={email}
            required
          />

          <Textfield
            onChange={e => {
              e.preventDefault()
              setPassword(e.target.value)
            }}
            label='Password*'
            floatingLabel
            style={{ width: '100%' }}
            type='password'
            className='new-password'
            value={password}
            required
            onKeyUp={(event) => {
              let code
              if (event.key !== undefined) {
                code = event.key;
              } else if (event.keyIdentifier !== undefined) {
                code = event.keyIdentifier;
              } else if (event.keyCode !== undefined) {
                code = event.keyCode;
              }
              if (code === 13 || code === 'Enter') {
                loginUser({
                  email, password, userApi, setLoader, redirectUrl, history, addToast
                })
              }
            }}
          />

          {isLoderVisble && <ShowLoader />}
          {!isLoderVisble && <Button
            raised
            colored
            style={{ width: '100%', margin: '8% 0 10%' }}
            onClick={async () => {
              loginUser({
                email, password, userApi, setLoader, redirectUrl, history, addToast
              })
            }}
          >
            Sign In
                    </Button>}

          <div className='signup-text'>
            Create a new account?
            <Link to={`/signup?redirectUrl=${redirectUrl}`}>Sign up </Link> <br />
            <Link to={`/reset/password?redirectUrl=${redirectUrl}`}>Forget password?</Link>
          </div>
        </div>
      </Card>
    </AppLayout>
  )
}

export default LoginFrom
