import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import SetPassword from '../Auth/SetPassword/SetPassword'
import { ToastProvider } from 'react-toast-notifications'
import LoginForm from '../Auth/Login/Login'
import ForgetPassword from '../Auth/ForgetPassword/ForgetPassword'
import Singup from '../Auth/Signup/Signup'
import './style.css'
import { isLogin } from '../shared/service/auth'
import LogoutForm from '../Auth/Logout/Logout'
import Util from '../shared/service/util'

const App = () => {
  let _isLogin = isLogin()

  if (_isLogin) {
    Util.redirectToApp()
  }

  return (
    <ToastProvider>
      <Router>
        <Switch>
          <Route path='/set-password'>
            <SetPassword />
          </Route>
          <Route path='/signin'>
            <LoginForm />
          </Route>
          <Route path='/reset/password'>
            <ForgetPassword />
          </Route>
          <Route path='/signup'>
            <Singup />
          </Route>
          <Route path='/logout'>
            <LogoutForm />
          </Route>
          <Route path='/'>
            {!_isLogin && <LoginForm />}
          </Route>
        </Switch>
      </Router>
    </ToastProvider>
  )
}

export default App