import React, { useState } from 'react'
import { Card, CardTitle, Textfield, Button } from 'react-mdl'
import { useToasts } from 'react-toast-notifications'
import { Link } from 'react-router-dom'
import UserApi from '../../../services/api/user'
import { AppLayout } from '../../shared/compoent/app-layout'
import { useRedirectQuery } from '../../shared/hooks/query-param'
import { ShowLoader } from '../../shared/compoent/loader'



const ForgetPassword = () => {

  let [email, setEmail] = useState('')
  const { addToast } = useToasts()
  let [isLoderVisble, setLoader] = useState(false)

  const userApi = new UserApi()
  const redirectUrl = useRedirectQuery()

  return (
    <AppLayout>
      <Card shadow={5}>
        <CardTitle className='header-title'>
          Enter your email address
        </CardTitle>
        <div style={{ margin: '0 5%' }}>
          <Textfield
            onChange={e => {
              setEmail(e.target.value)
            }}
            label='Email*'
            floatingLabel
            style={{ width: '100%' }}
            type='email'
            value={email}
            required
          />
          {isLoderVisble && <ShowLoader />}
          {!isLoderVisble && <Button
            raised
            colored
            style={{ width: '100%', margin: '8% 0 10%' }}
            onClick={async () => {

              if (!email) {
                return addToast('Email id is required', {
                  appearance: 'error',
                  autoDismiss: true
                })
              } else {

                try {
                  setLoader(true)
                  let res = await userApi.forgetPassword(email)
                  setLoader(false)

                  if (res.data && res.data.userID) {
                    addToast('Check your email for reset password link', {
                      appearance: 'success.',
                      autoDismiss: true
                    })
                  }
                } catch (error) {
                  setLoader(false)
                  addToast(error.message, {
                    appearance: 'error',
                    autoDismiss: true
                  })
                }
              }

            }}
          >
            Send Reset Link
          </Button>}

          <div className='signup-text'>
            <Link to={`/signin?redirectUrl=${redirectUrl}`}>Sign in</Link>
          </div>
        </div>
      </Card>
    </AppLayout>
  )
}

export default ForgetPassword