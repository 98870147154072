import React from 'react'
import { Spinner } from 'react-mdl'



const ShowLoader = () => {
    return (<div style={{ textAlign: 'center', marginBottom: '4%' }}>
        <Spinner />
    </div>)
}

export {
    ShowLoader
}