import React, { useState } from 'react'
import { Card, CardTitle, Textfield, Button } from 'react-mdl'
import { useToasts } from 'react-toast-notifications'
import { useLocation } from 'react-router-dom'
import UserApi from '../../../services/api/user'
import { AppLayout } from '../../shared/compoent/app-layout'


const ResetForm = () => {
  let [password, setPassword] = useState('')
  let [confirmPassword, setconfirmPassword] = useState('')

  const { addToast } = useToasts()
  let query = new URLSearchParams(useLocation().search)
  const tokenId = query.get('token')
  const userApi = new UserApi()

  return (
    <AppLayout>
      <Card shadow={5}>
        <CardTitle style={{ justifyContent: 'center' }}>
          Reset Password
            </CardTitle>
        <div style={{ margin: '0 5%' }}>
          <Textfield
            onChange={e => {
              setPassword(e.target.value)
            }}
            label='New Password*'
            floatingLabel
            style={{ width: '100%' }}
            type='password'
            className='new-password'
            value={password}
            required
          />

          <Textfield
            onChange={e => {
              e.preventDefault()
              setconfirmPassword(e.target.value)
            }}
            label='Confirm Password*'
            floatingLabel
            style={{ width: '100%' }}
            type='password'
            className='new-password'
            value={confirmPassword}
            required
          />

          <Button
            raised
            colored
            style={{ width: '100%', margin: '8% 0 10%' }}
            onClick={async () => {
              if (password === confirmPassword && password.length > 0) {
                try {
                  let res = await userApi.setPassword(tokenId, password)
                  if (
                    res &&
                    res.data &&
                    res.data.admin &&
                    res.data.admin.name
                  ) {
                    addToast(
                      `Hi ${res.data.admin.name}, 
                        Your password change successfully!`,
                      {
                        appearance: 'success',
                        autoDismiss: true
                      }
                    )
                  }
                  console.log('res', res)
                } catch (error) {
                  console.log('error', error)
                  addToast(error.message, {
                    appearance: 'error',
                    autoDismiss: true
                  })
                }
              } else {
                addToast('Password not matching', {
                  appearance: 'error',
                  autoDismiss: true
                })
              }
            }}
          >
            Change Password
              </Button>
        </div>
      </Card>
    </AppLayout >
  )
}

export default ResetForm