import { getToken } from '../../components/shared/service/auth'
import Api from './api'

let baseUrl = process.env.REACT_APP_URL

const subscriber = baseUrl + '/subscriber'
const setPasswordURL = subscriber + '/set-password'
const LoginURL = subscriber + '/login'
const SignupURL = subscriber + '/SignUp'
const forgetPasswordURL = subscriber + '/forget-password'
const subscriberDetailURL = subscriber

export default class UserApi {
  api = new Api()
  setPassword(tokenId, password) {
    let option = this.api.getOptions(setPasswordURL, { tokenId, password })
    return this.api.postJSON(option)
  }

  login(email, password) {
    let option = this.api.getOptions(LoginURL, { email, password, type: 'webapp' })
    return this.api.postJSON(option)
  }

  signup(body) {
    let option = this.api.getOptions(SignupURL, { ...body })
    return this.api.postJSON(option)
  }

  forgetPassword(email) {
    let option = this.api.getOptions(forgetPasswordURL, { email })
    return this.api.postJSON(option)
  }

  subscriberDetail() {
    let token = getToken()
    let option = this.api.getOptions(subscriberDetailURL, null, null, { authToken: token })
    return this.api.getJSON(option)
  }
}
