import React from 'react'
import { Grid, Cell } from 'react-mdl'
const AppLayout = ({ children }) => {
    return (
        <div style={{
            display: 'flex',
            backgroundColor: '#F7F6F9',
            height: '100vh'
        }}>
            <Grid>
                <Cell col={12} tablet={12} phone={12}>
                    <div>
                        <div className='gymbook-title'>GymBook</div>
                        {children}
                    </div>
                </Cell>
            </Grid>

        </div>
    )
}

export {
    AppLayout
}