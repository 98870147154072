import Cookies from 'universal-cookie';
import { encryptData, decryptData } from './encrypt'
const cookies = new Cookies();

const getYearExtensionDate = () => {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var c = new Date(year + 1, month, day);
    return c
}

const setCookie = (key, value) => {
    cookies.set(key,
        encryptData(value), {
        path: '/',
        domain: process.env.REACT_APP_HOST,
        secure: false,
        expires: getYearExtensionDate(),
        sameSite: 'lax'
    });
}

const getCookie = (key) => {
    return decryptData(cookies.get(key))
}

const deleteCookie = (key) => {
    cookies.remove(key, {
        path: '/',
        domain: process.env.REACT_APP_HOST,
        secure: false,
        sameSite: 'lax'
    })
}

export {
    setCookie,
    getCookie,
    deleteCookie
}