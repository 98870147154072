import React,{useState} from 'react'
import { Card, CardTitle, Button } from 'react-mdl'
import { AppLayout } from '../../shared/compoent/app-layout'
import { useRedirectQuery } from '../../shared/hooks/query-param'
import { deleteToken } from '../../shared/service/auth'
import Util from '../../shared/service/util'
import { useHistory } from "react-router-dom";
import UserApi from '../../../services/api/user'
import { useToasts } from 'react-toast-notifications'
import { ShowLoader } from '../../shared/compoent/loader'


const LogoutForm = () => {
    const redirectUrl = useRedirectQuery()
    const history = useHistory();


    if (Util.isNotNull(redirectUrl)) {
        deleteToken()
        Util.redirectUrl(redirectUrl)
    }

    let [isLoaderVisible, setLoader] = useState(true)
    const userApi = new UserApi()
    const { addToast } = useToasts()
    const [subscriberDetail, setSubscriberDetail] = useState({name:'', email:''}) 
    if(isLoaderVisible) {
        userApi.subscriberDetail()
        .then((subscriber) => {
            setLoader(false)
            setSubscriberDetail({
                email: subscriber?.data?.admin?.email,
                name: subscriber?.data?.admin?.name
          })
        })
        .catch((error) => {
            setLoader(false)
            console.log(error)
            addToast(error.message, {
                appearance: 'error',
                autoDismiss: true
            })
        })
    }
  


    return (
        <AppLayout>
            <Card shadow={5}>
                <CardTitle style={{ justifyContent: 'center' }}>
                    You are logged in successfully as:
                   
                </CardTitle>
                <div style={{ margin: '0 5%' }}>
                <div style={{
                    textAlign:'center',
                    fontSize:'1.5rem',
                    lineHeight:1.5
                }}>
                    {subscriberDetail.name} <br/>

                    {subscriberDetail.email}
                </div>

                {isLoaderVisible && <ShowLoader />}
                {!isLoaderVisible && <Button
                        raised
                        colored
                        style={{ width: '100%', margin: '8% 0 10%' }}
                        onClick={() => {
                            deleteToken()
                            history.push('/signin');
                        }}
                    >
                        LogOut
              </Button>}

                    
                </div>
            </Card>
        </AppLayout >
    )
}

export default LogoutForm