import React, { useState } from 'react'
import { Card, CardTitle, Textfield, Button } from 'react-mdl'
import { Link } from 'react-router-dom'
import UserApi from '../../../services/api/user'
import { AppLayout } from '../../shared/compoent/app-layout'
import { useToasts } from 'react-toast-notifications'
import { setToken } from '../../shared/service/auth'
import { useRedirectQuery } from '../../shared/hooks/query-param'
import Util from '../../shared/service/util'
import { ShowLoader } from '../../shared/compoent/loader'




const SignUpForm = () => {
  let [subName, setSubName] = useState('')
  let [name, setName] = useState('')
  let [email, setEmail] = useState('')
  let [password, setPassword] = useState('')
  let [mobile, setMobile] = useState('')
  let [refferalCode, setRefferalCode] = useState('')
  let [isLoderVisble, setLoader] = useState(false)



  const { addToast } = useToasts()
  const userApi = new UserApi()
  const redirectUrl = useRedirectQuery()

  return (
    <AppLayout>
      <Card shadow={5}>
        <CardTitle className='header-title'>Create a new aacount</CardTitle>
        <div style={{ margin: '0 5%' }}>
          <Textfield
            onChange={e => {
              setSubName(e.target.value)
            }}
            label='Gym Name*'
            floatingLabel
            style={{ width: '100%' }}
            type='text'
            value={subName}
            required
          />

          <Textfield
            onChange={e => {
              setName(e.target.value)
            }}
            label='Name*'
            floatingLabel
            style={{ width: '100%' }}
            type='text'
            value={name}
            required
          />

          <Textfield
            onChange={e => {
              setMobile(e.target.value)
            }}
            label='Mobile*'
            floatingLabel
            style={{ width: '100%' }}
            type='number'
            value={mobile}
            required
          />

          <Textfield
            onChange={e => {
              setEmail(e.target.value)
            }}
            label='Email*'
            floatingLabel
            style={{ width: '100%' }}
            type='email'
            value={email}
            required
          />

          <Textfield
            onChange={e => {
              setPassword(e.target.value)
            }}
            label='Password*'
            floatingLabel
            style={{ width: '100%' }}
            type='password'
            value={password}
            required
          />

          <Textfield
            onChange={e => {
              setRefferalCode(e.target.value)
            }}
            label='Referral Code(OPTIONAL)'
            floatingLabel
            style={{ width: '100%' }}
            type='text'
            value={refferalCode}
            required
          />

          {isLoderVisble && <ShowLoader />}
          {!isLoderVisble &&
            <Button
              raised
              colored
              style={{ width: '100%', margin: '8% 0 10%' }}
              onClick={async () => {
                try {


                  if (!subName) {
                    return addToast('Gym name is required', {
                      appearance: 'error',
                      autoDismiss: true
                    })

                  } else if (!name) {

                    return addToast('Name is required', {
                      appearance: 'error',
                      autoDismiss: true
                    })

                  } else if (!mobile) {
                    return addToast('mobile is required', {
                      appearance: 'error',
                      autoDismiss: true
                    })
                  } else if (!email) {
                    return addToast('Email is required', {
                      appearance: 'error',
                      autoDismiss: true
                    })

                  } else if (!password) {
                    return addToast('Password is required', {
                      appearance: 'error',
                      autoDismiss: true
                    })

                  }

                  setLoader(true)

                  let signup = await userApi.signup({
                    subName,
                    name,
                    email,
                    password,
                    mobile,
                    refferalCode
                  })
                  setToken(signup.authToken)
                  setLoader(false)

                  Util.redirectUrl(redirectUrl)

                } catch (error) {
                  setLoader(false)

                  addToast(error.message, {
                    appearance: 'error',
                    autoDismiss: true
                  })
                }
              }}
            >
              Sign Up
            </Button>}

          <div className='signup-text'>
            Already have an account?
              <Link to={`/signin?redirectUrl=${redirectUrl}`}>Sign in</Link>
          </div>
        </div>
      </Card>
    </AppLayout>

  )
}



export default SignUpForm
