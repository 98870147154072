class Util {
    static isNull(val) {
        return val === null || val === undefined
    }

    static isNotNull(val) {
        return !Util.isNull(val)
    }

    static redirectUrl(redirectUrl) {
        if (Util.isNotNull(redirectUrl)) {
            window.location.assign(redirectUrl);
        }
    }

    static redirectToApp() {
        let redirectUrl = 'https://app.gymbook.in/'
        if (process.env.REACT_APP_STAGE === 'DEV') {
            redirectUrl = 'http://localhost:3002/'
        }
        Util.redirectUrl(redirectUrl)
    }
}

export default Util
export { Util }